module.exports = {
  siteTitle: 'Bagno Europa, 71 - Cesenatico', // <title>
  manifestName: 'Il Bagno Europa, 71 si trova a Cesenatico sulla spiaggia di Levante, a due passi dal molo.',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#187589',
  manifestThemeColor: '#187589',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  heading: 'Bagno EUROPA, 71',
  subHeading: 'Per le tue vacanze e il tuo relax nel cuore di Cesenatico',
  // social
  socialLinks: [
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/bagnoeuropa71/',
      value: 'www.facebook.com/bagnoeuropa71/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:bagno.europa71@gmail.com',
      value: 'bagno.europa71@gmail.com',
    },
  ],
  phone: '0547.80256 - 388.09.85.567',
  address: 'Via Piave 71, Cesenatico (FC)',
};
