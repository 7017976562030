import React from 'react'
import GoogleMapReact from 'google-map-react'

const defaultProps = {
    center: {
        lat: 44.2056269,
        lng: 12.4036527,
    },
    zoom: 16,
}

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = () => (
    <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAtPh5HoXNXtCQHFDxkFzcOiod9HGNEl04' }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}>
            <AnyReactComponent
                lat={44.2056269}
                lng={12.4036527}
                text={'Bagno Europa 71'}
            />
        </GoogleMapReact>
    </div>
)

export default GoogleMap