import React from 'react';
import config from '../../config';
import GoogleMap from '../components/GoogleMap'
import { useIntl } from "gatsby-plugin-intl"

const Footer = () => {
  const intl = useIntl()
  return (
        <section id="footer">
      <div className="inner">
        <h2 className="major" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "f_t_dove_siamo" })}} />
        <p dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "f_p_dove_siamo" })}} />
        <div className="googleMap">
          <GoogleMap />
        </div>
        <ul className="contact">
          <li className="fa-home">{config.address}</li>
          <li className="fa-phone">{config.phone}</li>

          {config.socialLinks.map(social => {
            const { icon, url, value } = social;
            return (
              <li className={`${icon}`} key={url}>
                <a href={url}>{value}</a>
              </li>
            );
          })}
        </ul>
        <ul className="copyright">
          <li>&copy; Bagno Europa di Pericoli Greta &amp; C. S.N.C / Via Piave 71 - 47042 Cesenatico (FC)</li>
          <li>
            P.iva 02232870408 / REA FO- 253135
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Footer;
