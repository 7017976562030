import React, { useState } from 'react';
import Nav from './Nav';
import pic8 from "../assets/images/bagno_europa_logo.png";
import {Link, changeLocale, IntlContextConsumer} from "gatsby-plugin-intl"

export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);
    const languageName = {
        it: "https://hatscripts.github.io/circle-flags/flags/it.svg",
        fr: "https://hatscripts.github.io/circle-flags/flags/fr.svg",
        en: "https://hatscripts.github.io/circle-flags/flags/us.svg",
        de: "https://hatscripts.github.io/circle-flags/flags/de.svg"
    }
    return (
    <>

        <header id="header" className={`${fullMenu ? '' : 'alt'}`}>

            <div className="logo">
              <img src={pic8} alt="Bagno Europa Cesenatico"/>
          </div>
          {
        <nav>
            <IntlContextConsumer>
                {({ languages, language: currentLocale }) =>
                    languages.map(language => (
                        <a key={language}
                            onClick={() => changeLocale(language)}
                            style={{
                                cursor: `pointer`,
                            }}
                        ><span className="language"><img width={40} src={languageName[language]}/></span></a>
                    ))
                }
            </IntlContextConsumer>
        </nav>
       }
      </header>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </>
  );
}
